<template>
  <div class="news-details">
    <div class="crumbs">
      当前位置： <a href="#/Home">首页</a> > <a href="#/News/NewsList">新闻资讯</a> >
      <span style="color: #00aeeb">资讯详情</span>
    </div>
    <div class="news">
      <div class="title">
        <h2>{{ data.newsTitle }}</h2>
        <p>
          【发布时间：{{ data.createTime | time }}　作者：{{
            data.newsAuthor
          }}】
        </p>
      </div>
      <div class="dashed"></div>
      <div>
        <!-- <pdf :src="src" style="width: 50%; margin-left: 400px"></pdf> -->
      </div>
      <div class="news_content" v-html="data.newsContent"></div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/validate";
import pdf from "vue-pdf";

export default {
  name: "news-details",

  components: { pdf },
  data() {
    return {
      src: "",
      data: "http://hwpicture.realbrand.net/982f86eeb8ef470fae06adbee68a5d46.pdf",
    };
  },
  filters: {
    //处理函数
    time(value) {
      return formatDate(value);
    },
  },
  created() {
    this.getNewsDetails();
    // this.src = pdf.createLoadingTask(this.src);
  },
  mounted() {},
  methods: {
    //获取新闻详情
    async getNewsDetails() {
      const data = await this.$API.class.NewsDetails({
        object: {
          newsCode: this.$route.params.newsCode,
        },
      });
      console.log(data.data.data);
      this.data = data.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.news-details {
  display: flex;
  margin-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .crumbs {
    margin: 20px 0;
    width: 1200px;
    color: #666666;
    font-size: 14px;
    a {
      color: #666666;
    }
    a:hover {
      color: #00aeeb;
    }
  }
  .news {
    width: 1200px;
    background: #fff;
    padding: 30px 60px;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: #333333;
        text-align: center;
      }
      p {
        font-size: 14px;
        line-height: 19px;
        color: #666666;
        margin: 10px 0 30px 0;
      }
    }
    .dashed {
      border-bottom: 1px dashed #ccc;
    }
    .news_content {
      padding: 24px 0;
    }
  }
}
</style>
<style  lang="scss">
.news_content {
  p {
    margin: 16px 0 !important;
  }
  strong,
  b {
    font-weight: 900 !important;
  }
}
</style>